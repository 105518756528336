import React from 'react';
import './contactinfo.css';
import reviewUs from "./Assets/reviewUs.png";

function ContactInfo() {
  return (
    <div className="container-wrapper contact">
      <h1>Contact Information</h1>
      <div className="body-style contactBody">
        <div className="sub-content subcontent">
          <p className="p-style">Phone: 96363537</p>

          <p className="p-style">
          50 East Coast Rd #01-135 Roxy Sq II <br></br>Singapore 428769
          </p>
        </div>

        <a href="https://www.google.com/maps/place/Kuts+%26+Kurls/@1.3043651,103.9040224,15z/data=!4m2!3m1!1s0x0:0xb0d1729e18dea4c?sa=X&ved=1t:2428&ictx=111">
          <div className="sub-content subcontent">
            <img src={reviewUs} className="review-img" alt="reviewUs" />
          </div>
        </a>
      </div>
      <h3 className="h3-style">Service Hours</h3>
      <p className="opening-time">
       Opens daily: 10am - 9pm
      </p>
    </div>
  );
}

export default ContactInfo;