import React from "react";
import './Maps.css';

function Maps() {
  return (
    <div>
      <div className="flex-wrapper maps">
        <h1>Locate Us!</h1>
        <b></b>
        <div className="grid-style GridMaps">
          <div className="grid1maps">
            <iframe
              className="grid-maps"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.137882218309!2d103.9040224!3d1.3043651!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19233fdd5695%3A0xb0d1729e18dea4c!2sKuts%20%26%20Kurls!5e0!3m2!1sen!2ssg!4v1722354665505!5m2!1sen!2ssg"
              referrerPolicy="no-referrer-when-downgrade"
              title="Map 1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maps;